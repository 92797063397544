import React from "react";
import { SvgIcons } from "../../assets/icons/SvgIcons";
import { motion } from "framer-motion";

const CBOpenDexIcon = ({ showChatBot }) => {
  return (
    <button class="chat-btn" onClick={() => showChatBot()}>
      <motion.div
        initial={{ scale: 0 }}
        animate={{ rotate: 360, scale: 1 }}
        transition={{
          type: "spring",
          stiffness: 550,
          damping: 100,
        }}
      >
        {SvgIcons.dexIcon}
      </motion.div>
    </button>
  );
};

export default CBOpenDexIcon;
