import React, { useEffect, useState } from "react";
import { SvgIcons } from "../../assets/icons/SvgIcons";
import { Avatar } from "antd";

const CBTypingLoader = () => {
  const [loadingText, setloadingText] = useState("Thinking");

  useEffect(() => {
    const textSequence = ["Fetching data", "Processing", "Formatting"];

    const timer1 = setTimeout(() => {
      setloadingText(textSequence[0]);
    }, 1000);
    const timer2 = setTimeout(() => {
      setloadingText(textSequence[1]);
    }, 1800);
    const timer3 = setTimeout(() => {
      setloadingText(textSequence[2]);
    }, 2600);

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
      clearTimeout(timer3);
    };
  }, []);

  return (
    <div class="bot-msg bot-msg-loader">
      <div className="bot-msg-icon">
        <Avatar icon={SvgIcons.dexIconSm} style={{ background: "#04224b" }} />
      </div>

      <div className="bot-msg-loader-text">
        <span>{loadingText}</span>
        <div className="typing bot-msg">
          <div class="snippet" data-title="dot-falling">
            <div class="stage">
              <div class="dot-falling"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CBTypingLoader;
