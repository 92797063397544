export const debounce = (func, interval, clearFunc, transcript) => {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(
      () => {
        timer = null;
        func.apply(context, args);
        clearFunc(transcript);
      },
      interval ? interval : 5000
    );
  };
};

export const asyncLocalStorage = {
  setItem: async function (key, value) {
    await Promise.resolve();
    localStorage.setItem(key, value);
  },
  getItem: async function (key) {
    await Promise.resolve();
    return localStorage.getItem(key);
  },
};
