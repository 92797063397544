import { CBAxiosAPI } from "../../AxiosAPI";
import { config } from "../../config";

export const postTheMessage = async (data) => {
  return CBAxiosAPI({
    method: "POST",
    url: `/chat`,
    data: data,
  })
    .then((response) => response)
    .catch((error) => error);
};

export const postTheFeedback = async (data) => {
  return CBAxiosAPI({
    method: "POST",
    url: `/feedback`,
    data: data,
  })
    .then((response) => response)
    .catch((error) => error);
};
