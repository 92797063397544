export const SvgIcons = {
  dexIcon: (
    <svg
      width="21"
      height="25"
      viewBox="0 0 21 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle fill="currentColor" cx="3.85234" cy="3.82499" r="0.696087" />
      <circle fill="currentColor" cx="3.93358" cy="20.0664" r="1.16014" />
      <circle fill="currentColor" cx="3.85215" cy="7.02793" r="0.695901" />
      <circle fill="currentColor" cx="3.85215" cy="9.91074" r="0.695901" />
      <circle fill="currentColor" cx="3.93358" cy="13.5703" r="1.16014" />
      <circle fill="currentColor" cx="3.93358" cy="16.8203" r="1.16014" />
      <circle fill="currentColor" cx="7.16484" cy="3.82499" r="0.696087" />
      <circle fill="currentColor" cx="7.18358" cy="20.0664" r="1.16014" />
      <circle fill="currentColor" cx="7.16484" cy="7.02812" r="0.696087" />
      <circle fill="currentColor" cx="7.16465" cy="9.91074" r="0.695901" />
      <circle fill="currentColor" cx="7.16465" cy="13.5397" r="0.695901" />
      <circle fill="currentColor" cx="7.18358" cy="16.8203" r="1.16014" />
      <circle fill="currentColor" cx="10.368" cy="3.82499" r="0.696087" />
      <circle fill="currentColor" cx="13.2117" cy="3.82499" r="0.696087" />
      <circle fill="currentColor" cx="10.4336" cy="20.0664" r="1.16014" />
      <circle fill="currentColor" cx="13.6725" cy="20.0494" r="0.695901" />
      <circle fill="currentColor" cx="10.368" cy="7.02812" r="0.696087" />
      <circle fill="currentColor" cx="13.6883" cy="7.07304" r="0.696087" />
      <circle fill="currentColor" cx="16.9226" cy="7.07304" r="0.696087" />
      <circle fill="currentColor" cx="10.368" cy="10.3367" r="0.696087" />
      <circle fill="currentColor" cx="13.6726" cy="10.3367" r="0.696087" />
      <circle fill="currentColor" cx="16.9226" cy="10.323" r="0.696087" />
      <circle fill="currentColor" cx="10.3678" cy="13.5397" r="0.695901" />
      <circle fill="currentColor" cx="13.6883" cy="13.5711" r="0.696087" />
      <circle fill="currentColor" cx="16.9226" cy="13.5711" r="0.696087" />
      <circle fill="currentColor" cx="10.3678" cy="16.7408" r="0.695901" />
      <circle fill="currentColor" cx="13.6725" cy="16.7408" r="0.695901" />
      <circle fill="currentColor" cx="16.9226" cy="16.8191" r="0.696087" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1.43345C0 0.641776 0.641776 0 1.43345 0H13.3995C13.7714 0 14.1288 0.144576 14.3961 0.403188L20.5632 6.36916C20.8424 6.63922 21 7.01101 21 7.39942V16.612C21 16.999 20.8435 17.3696 20.5661 17.6395L14.3979 23.6401C14.1303 23.9004 13.7716 24.0461 13.3983 24.0461H1.43345C0.641776 24.0461 0 23.4043 0 22.6126V1.43345ZM13.3995 1.43345H1.43345V22.6126H13.3983L19.5666 16.612V7.39942L13.3995 1.43345Z"
        fill="currentColor"
      />
    </svg>
  ),
  dexIconSm: (
    <svg
      width="10"
      height="15"
      viewBox="0 0 21 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle fill="currentColor" cx="3.85234" cy="3.82499" r="0.696087" />
      <circle fill="currentColor" cx="3.93358" cy="20.0664" r="1.16014" />
      <circle fill="currentColor" cx="3.85215" cy="7.02793" r="0.695901" />
      <circle fill="currentColor" cx="3.85215" cy="9.91074" r="0.695901" />
      <circle fill="currentColor" cx="3.93358" cy="13.5703" r="1.16014" />
      <circle fill="currentColor" cx="3.93358" cy="16.8203" r="1.16014" />
      <circle fill="currentColor" cx="7.16484" cy="3.82499" r="0.696087" />
      <circle fill="currentColor" cx="7.18358" cy="20.0664" r="1.16014" />
      <circle fill="currentColor" cx="7.16484" cy="7.02812" r="0.696087" />
      <circle fill="currentColor" cx="7.16465" cy="9.91074" r="0.695901" />
      <circle fill="currentColor" cx="7.16465" cy="13.5397" r="0.695901" />
      <circle fill="currentColor" cx="7.18358" cy="16.8203" r="1.16014" />
      <circle fill="currentColor" cx="10.368" cy="3.82499" r="0.696087" />
      <circle fill="currentColor" cx="13.2117" cy="3.82499" r="0.696087" />
      <circle fill="currentColor" cx="10.4336" cy="20.0664" r="1.16014" />
      <circle fill="currentColor" cx="13.6725" cy="20.0494" r="0.695901" />
      <circle fill="currentColor" cx="10.368" cy="7.02812" r="0.696087" />
      <circle fill="currentColor" cx="13.6883" cy="7.07304" r="0.696087" />
      <circle fill="currentColor" cx="16.9226" cy="7.07304" r="0.696087" />
      <circle fill="currentColor" cx="10.368" cy="10.3367" r="0.696087" />
      <circle fill="currentColor" cx="13.6726" cy="10.3367" r="0.696087" />
      <circle fill="currentColor" cx="16.9226" cy="10.323" r="0.696087" />
      <circle fill="currentColor" cx="10.3678" cy="13.5397" r="0.695901" />
      <circle fill="currentColor" cx="13.6883" cy="13.5711" r="0.696087" />
      <circle fill="currentColor" cx="16.9226" cy="13.5711" r="0.696087" />
      <circle fill="currentColor" cx="10.3678" cy="16.7408" r="0.695901" />
      <circle fill="currentColor" cx="13.6725" cy="16.7408" r="0.695901" />
      <circle fill="currentColor" cx="16.9226" cy="16.8191" r="0.696087" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1.43345C0 0.641776 0.641776 0 1.43345 0H13.3995C13.7714 0 14.1288 0.144576 14.3961 0.403188L20.5632 6.36916C20.8424 6.63922 21 7.01101 21 7.39942V16.612C21 16.999 20.8435 17.3696 20.5661 17.6395L14.3979 23.6401C14.1303 23.9004 13.7716 24.0461 13.3983 24.0461H1.43345C0.641776 24.0461 0 23.4043 0 22.6126V1.43345ZM13.3995 1.43345H1.43345V22.6126H13.3983L19.5666 16.612V7.39942L13.3995 1.43345Z"
        fill="currentColor"
      />
    </svg>
  ),
  dexIconMd: (
    <svg
      width="15"
      height="20"
      viewBox="0 0 21 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle fill="currentColor" cx="3.85234" cy="3.82499" r="0.696087" />
      <circle fill="currentColor" cx="3.93358" cy="20.0664" r="1.16014" />
      <circle fill="currentColor" cx="3.85215" cy="7.02793" r="0.695901" />
      <circle fill="currentColor" cx="3.85215" cy="9.91074" r="0.695901" />
      <circle fill="currentColor" cx="3.93358" cy="13.5703" r="1.16014" />
      <circle fill="currentColor" cx="3.93358" cy="16.8203" r="1.16014" />
      <circle fill="currentColor" cx="7.16484" cy="3.82499" r="0.696087" />
      <circle fill="currentColor" cx="7.18358" cy="20.0664" r="1.16014" />
      <circle fill="currentColor" cx="7.16484" cy="7.02812" r="0.696087" />
      <circle fill="currentColor" cx="7.16465" cy="9.91074" r="0.695901" />
      <circle fill="currentColor" cx="7.16465" cy="13.5397" r="0.695901" />
      <circle fill="currentColor" cx="7.18358" cy="16.8203" r="1.16014" />
      <circle fill="currentColor" cx="10.368" cy="3.82499" r="0.696087" />
      <circle fill="currentColor" cx="13.2117" cy="3.82499" r="0.696087" />
      <circle fill="currentColor" cx="10.4336" cy="20.0664" r="1.16014" />
      <circle fill="currentColor" cx="13.6725" cy="20.0494" r="0.695901" />
      <circle fill="currentColor" cx="10.368" cy="7.02812" r="0.696087" />
      <circle fill="currentColor" cx="13.6883" cy="7.07304" r="0.696087" />
      <circle fill="currentColor" cx="16.9226" cy="7.07304" r="0.696087" />
      <circle fill="currentColor" cx="10.368" cy="10.3367" r="0.696087" />
      <circle fill="currentColor" cx="13.6726" cy="10.3367" r="0.696087" />
      <circle fill="currentColor" cx="16.9226" cy="10.323" r="0.696087" />
      <circle fill="currentColor" cx="10.3678" cy="13.5397" r="0.695901" />
      <circle fill="currentColor" cx="13.6883" cy="13.5711" r="0.696087" />
      <circle fill="currentColor" cx="16.9226" cy="13.5711" r="0.696087" />
      <circle fill="currentColor" cx="10.3678" cy="16.7408" r="0.695901" />
      <circle fill="currentColor" cx="13.6725" cy="16.7408" r="0.695901" />
      <circle fill="currentColor" cx="16.9226" cy="16.8191" r="0.696087" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1.43345C0 0.641776 0.641776 0 1.43345 0H13.3995C13.7714 0 14.1288 0.144576 14.3961 0.403188L20.5632 6.36916C20.8424 6.63922 21 7.01101 21 7.39942V16.612C21 16.999 20.8435 17.3696 20.5661 17.6395L14.3979 23.6401C14.1303 23.9004 13.7716 24.0461 13.3983 24.0461H1.43345C0.641776 24.0461 0 23.4043 0 22.6126V1.43345ZM13.3995 1.43345H1.43345V22.6126H13.3983L19.5666 16.612V7.39942L13.3995 1.43345Z"
        fill="currentColor"
      />
    </svg>
  ),
};
