import React, { useRef, useState } from "react";
import { SvgIcons } from "../../assets/icons/SvgIcons";
import { Avatar } from "antd";
import {
  ExpandAltOutlined,
  LeftOutlined,
  ShrinkOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Select, Space } from "antd";
import { asyncLocalStorage } from "../../services/commonService";
import { useSelector } from "react-redux";
import portfolioSummary from "../../assets/mp3/portfoioSummary.mp3";

const dummyLogins = [
  "Management",
  "RM",
  "ARM",
  "IC",
  "Compliance",
  "OPS",
  "Client",
];

const CBChatHead = ({
  closeChatFullScreen,
  openChatFullScreen,
  isChatFullScreen,
  selectedUserRole,
  onUserRoleChange,
  closeChatBot,
}) => {
  const userDetails = useSelector((state) => state.login.userDetails);
  const userName = userDetails?.username ? userDetails?.username : "";
  // const us
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPlayed, setIsPlayed] = useState(false);
  const audioRef = useRef(null);

  const toggleAudio = () => {
    const audio = audioRef?.current;
    !isPlayed && setIsPlayed(true);
    if (isPlaying) {
      audio?.pause();
    } else {
      audio?.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <div className="chat-header">
      <div className="chat-header-left">
        <div className="chatbot-img cursor-pointer d-flex align-center gap-5">
          <div className="chatbot-back-to-home" onClick={closeChatBot}>
            <LeftOutlined style={{ fontSize: "20px" }} />
          </div>
          <Avatar
            icon={SvgIcons.dexIconMd}
            size="large"
            style={{ background: "#04224b" }}
          />
        </div>
        {/* <h4 className="bot-title">Chat With My Portfolio</h4> */}
      </div>
      {/* <div className="cb-head-userrole">Welcome {userName}</div> */}

      <div className="cb-chatbot-fullscreen">
        {/* <Space wrap>
          <Select
            style={{
              width: 120,
            }}
            defaultValue={dummyLogins[2]}
            value={selectedUserRole}
            onChange={onUserRoleChange}
            options={dummyLogins.map((data) => ({
              label: data,
              value: data,
            }))}
          />
        </Space> */}
        <button
          class="chat-submit"
          onClick={() => {
            toggleAudio();
          }}
        >
          {isPlaying
            ? "Pause Portfolio Summary"
            : isPlayed
            ? "Resume Portfolio Summary"
            : "Summarize my Portfolio"}
        </button>
        <audio ref={audioRef} style={{ visibility: "hidden" }}>
          <source src={portfolioSummary} type="audio/mpeg" />
        </audio>
        <div className="cb-fullscreen-icon">
          {isChatFullScreen ? (
            <ShrinkOutlined
              onClick={() => closeChatFullScreen()}
              style={{ fontSize: "16px" }}
            />
          ) : (
            <ExpandAltOutlined
              onClick={() => openChatFullScreen()}
              style={{ fontSize: "16px" }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CBChatHead;
