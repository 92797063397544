import React from "react";

const CBFeedbackModalTitle = ({ className, icon, title }) => {
  return (
    <div className="cb-likModal-title">
      <div className={className}>{icon}</div>
      <div className="cb-likeModal-name">{title}</div>
    </div>
  );
};

export default CBFeedbackModalTitle;
