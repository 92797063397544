import jwt_decode from "jwt-decode";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import ChatBotHome from "./pages/ChatBotHome";
import CBRedirectPage from "./pages/RedirectPage/CBRedirectPage";
import { login, logout } from "./store/feature/loginSlice";

function App() {
  const dispatch = useDispatch();
  const env = process.env.REACT_APP_ENV || "development";
  console.log({ env });

  useEffect(() => {
    const token = localStorage.getItem("_token");
    if (token && token?.length) {
      const jwtTokenDecoded = jwt_decode(token);
      console.log({ jwtTokenDecoded });
      const tokenExp = jwtTokenDecoded?.exp;
      const presentDate = Date.now() / 1000;

      if (tokenExp < presentDate) {
        dispatch(logout());
        localStorage.removeItem("_token");
      } else {
        const userdetails = jwtTokenDecoded?.userDetail
          ? jwtTokenDecoded?.userDetail
          : {};
        dispatch(login(userdetails));
      }
    }
  }, []);

  return (
    <>
      <Routes>
        <Route path="/sso-callback" element={<CBRedirectPage />} />
        <Route path="/" element={<ChatBotHome />} />
      </Routes>
    </>
  );
}

export default App;
