import React, { useEffect } from "react";
import "./CBDashboardHeader.css";
import { SvgIcons } from "../../../assets/icons/SvgIcons";
import { Avatar, Popover } from "antd";
import { asyncLocalStorage } from "../../../services/commonService";
import { useNavigate } from "react-router-dom";
import { login, logout } from "../../../store/feature/loginSlice";
import { useDispatch, useSelector } from "react-redux";

const CBDashboardHeader = ({ isUserLoggedIn }) => {
  const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
  const userDetails = useSelector((state) => state.login.userDetails);
  const dispatch = useDispatch();

  const avatarName = userDetails?.firstName?.[0]
    ? userDetails?.firstName?.[0]
    : "" + userDetails?.lastName?.[0]
    ? userDetails?.lastName?.[0]
    : "";

  const userName = userDetails?.username ? userDetails?.username : "";

  const loginChatBot = async () => {
    const redirectionUrl = process.env.REACT_APP_URL_LOGIN
      ? process.env.REACT_APP_URL_LOGIN
      : "";

    const dexAuthorizeUrl = process.env.REACT_APP_LOGIN_AUTHORIZE_URL;

    const url = `${redirectionUrl}/authorize/${dexAuthorizeUrl}`;
    window.open(url, "_self");
  };

  const onLogout = () => {
    localStorage.removeItem("_token");
    dispatch(logout());
  };

  return (
    <div className="cb-dashboardHeader">
      <div className="d-flex gap-10 align-center">
        {SvgIcons.dexIcon}
        <span className="f-24 fw-800 aim-headeer-icon-name">DEX</span>
      </div>
      {isLoggedIn ? (
        <div className="cb-dashboardavatar">
          <div>
            <Popover placement="bottom" title={userName} trigger="hover">
              <Avatar>{avatarName}</Avatar>
            </Popover>
          </div>
          <div className="cb-btn" onClick={onLogout}>
            Log out
          </div>
        </div>
      ) : (
        <div className="cb-btn" onClick={loginChatBot}>
          Log in
        </div>
      )}
    </div>
  );
};

export default CBDashboardHeader;
