import { Typewriter } from "react-simple-typewriter";
import { useChatBotDashboard } from "../hooks/useChatBotDashboard";
import "./ChatBotDashboard.css";
import CBChatPopUp from "./Dashboard/CBChatPopUp";
import CBDashboardHeader from "./Dashboard/CBDashboardHeader/CBDashboardHeader";
import CBFullScreenModal from "./Dashboard/CBFullScreenModal";
import CBOpenDexIcon from "./Dashboard/CBOpenDexIcon";

const ChatBotDashboard = () => {
  const {
    isBotModal,
    chatMsgs,
    userMsg,
    refList,
    isOpenImage,
    fullScreenImageSrc,
    responseLoading,
    openImageFullscreen,
    closeImageFullscreen,
    showChatBot,
    handleType,
    sendMessageToBot,
    recordUserMessage,
    closeChatFullScreen,
    openChatFullScreen,
    isChatFullScreen,
    selectedUserRole,
    onUserRoleChange,
    isUserLoggedIn,
    loginDetails,
    handleLoginChange,
    onLoginSubmit,
    onLogout,
    loginChatBot,
    isLoggedIn,
    closeChatBot,
    // isAuthenticated,
  } = useChatBotDashboard();

  return (
    <>
      <div>
        <CBDashboardHeader
          isUserLoggedIn={isUserLoggedIn}
          onLogout={onLogout}
          loginChatBot={loginChatBot}
        />
      </div>
      <div id="chat-container">
        <div className="cb-dashboard-content">
          <p className="cb-dashboard-title">Simplifying Investment Decisions</p>
          <p className="cb-dashboard-title-italic">with</p>

          <p className="cb-dashboard-title">
            <Typewriter
              words={["DEX"]}
              cursor={false}
              cursorStyle="_"
              typeSpeed={700}
              deleteSpeed={100}
              delaySpeed={5000}
            />
          </p>
          {/* <div className="cb-dashboard-btn">
            <div className="cb-dashboard-btn-submit">
              <div
                className="cb-dashboard-btn-submit-field"
                onClick={loginChatBot}
              >
                Start with Dex
              </div>
            </div>
          </div> */}
        </div>

        <div>
          {isLoggedIn && <CBOpenDexIcon showChatBot={showChatBot} />}
          <CBChatPopUp
            handleType={handleType}
            sendMessageToBot={sendMessageToBot}
            recordUserMessage={recordUserMessage}
            responseLoading={responseLoading}
            openImageFullscreen={openImageFullscreen}
            isBotModal={isBotModal}
            chatMsgs={chatMsgs}
            userMsg={userMsg}
            refList={refList}
            closeChatFullScreen={closeChatFullScreen}
            openChatFullScreen={openChatFullScreen}
            isChatFullScreen={isChatFullScreen}
            selectedUserRole={selectedUserRole}
            onUserRoleChange={onUserRoleChange}
            isUserLoggedIn={isUserLoggedIn}
            loginDetails={loginDetails}
            handleLoginChange={handleLoginChange}
            onLoginSubmit={onLoginSubmit}
            onLogout={onLogout}
            closeChatBot={closeChatBot}
          />
        </div>
      </div>
      <CBFullScreenModal
        isOpenImage={isOpenImage}
        fullScreenImageSrc={fullScreenImageSrc}
        closeImageFullscreen={closeImageFullscreen}
      />
    </>
  );
};

export default ChatBotDashboard;
