import axios from "axios";
import { config } from "./config";
import { store } from "./store/store";
import jwt_decode from "jwt-decode";
import { logout } from "./store/feature/loginSlice";
import { infoNotification } from "./services/notification";

const CBAxiosAPI = axios.create({
  baseURL: config.APP_URL,
});

export const setAuthHeader = (token) => {
  CBAxiosAPI.defaults.headers = {
    "x-av-api-access-key": token,
  };
};

setAuthHeader(localStorage.getItem("_token"));

const responseFunc = (response) => {
  return response;
};

const errorFunc = async (error) => {
  if (error?.response?.status === 404) {
    console.log("/404");
  } else if (error?.response?.status === 500) {
    console.log("/500");
  } else if (error?.response?.status === 401) {
    store.dispatch(logout());
    localStorage.removeItem("_token");
    infoNotification("Unauthorized!");
  } else {
    console.log("/other-errors.");
  }
  return Promise.reject(error);
};

const verifyTokenRequestInterceptor = async (response) => {
  const cachedToken = localStorage.getItem("_token");

  if (cachedToken) {
    const jwtTokenDecoded = jwt_decode(cachedToken);
    const tokenExp = jwtTokenDecoded?.exp;
    const presentDate = Date.now() / 1000;

    if (tokenExp < presentDate) {
      store.dispatch(logout());
      localStorage.removeItem("_token");
      infoNotification("Session Timeout, Log in again!");
    }
    return response;
  } else {
    store.dispatch(logout());
    localStorage.removeItem("_token");
    infoNotification("Session Timeout, Log in again!");
  }
};

CBAxiosAPI.interceptors.response.use(responseFunc, errorFunc);
CBAxiosAPI.interceptors.request.use(verifyTokenRequestInterceptor);

export { CBAxiosAPI };
