import { Modal } from "antd";
import Title from "antd/es/typography/Title";
import React from "react";

const CDModal = ({ title, body, open, onCancel, width }) => {
  return (
    <div>
      {" "}
      <Modal
        title={title}
        centered
        open={open}
        onCancel={() => onCancel()}
        footer={null}
        width={width ? width : 1000}
      >
        {body}
      </Modal>
    </div>
  );
};

export default CDModal;
