import { DislikeOutlined, LikeOutlined, StarOutlined } from "@ant-design/icons";
import CDModal from "../Common/CDModal";
import CBChatBody from "./CBChatBody";
import CBChatFooter from "./CBChatFooter";
import CBChatHead from "./CBChatHead";
import CBFeedbackModalTitle from "../Common/CBFeedbackModalTitle";
import CBLoginModal from "../Login/CBLoginModal";
import useChatBotPopUp from "../../hooks/useChatBotPopUp";
import { motion } from "framer-motion";

const chatBotBodyVariants = {
  hidden: {
    opacity: 0,
    y: 50,
    transition: {
      duration: 1.4,
    },
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1.4,
    },
  },
};

const CBChatPopUp = ({
  handleType,
  sendMessageToBot,
  recordUserMessage,
  responseLoading,
  openImageFullscreen,
  isBotModal,
  chatMsgs,
  userMsg,
  refList,
  closeChatFullScreen,
  openChatFullScreen,
  isChatFullScreen,
  selectedUserRole,
  onUserRoleChange,
  isUserLoggedIn,
  loginDetails,
  handleLoginChange,
  onLoginSubmit,
  closeChatBot
}) => {
  const {
    isLikeBotMsg,
    isDislikeBotMsg,
    isDisALikeEnhancement,
    likedBotMsgBodyContent,
    disLikedBotMsgBodyContent,
    enhancementBotMsgBodyContent,
    openLikeBotMsgModal,
    openDislikeBotMsgModal,
    closeDislikeEnhancementModal,
    closeDislikedBotMsgModal,
    closeLikedBotMsgModal,
  } = useChatBotPopUp();

  return (
    <>
      <div
        class={`chat-popup ${isChatFullScreen && "chat-popup-fullscreen"}`}
        style={{ display: isBotModal ? "flex" : "none" }}
      >
        <CBChatHead
          closeChatFullScreen={closeChatFullScreen}
          openChatFullScreen={openChatFullScreen}
          isChatFullScreen={isChatFullScreen}
          selectedUserRole={selectedUserRole}
          onUserRoleChange={onUserRoleChange}
          closeChatBot={closeChatBot}
        />
        <CBChatBody
          handleType={handleType}
          chatMsgs={chatMsgs}
          refList={refList}
          sendMessageToBot={sendMessageToBot}
          openImageFullscreen={openImageFullscreen}
          responseLoading={responseLoading}
          isChatFullScreen={isChatFullScreen}
          LikeBotMsg={openLikeBotMsgModal}
          dislikeBotMsg={openDislikeBotMsgModal}
        />
        <CBChatFooter
          recordUserMessage={recordUserMessage}
          sendMessageToBot={sendMessageToBot}
          userMsg={userMsg}
        />

        {/* {!isUserLoggedIn && (
            <CBLoginModal
              loginDetails={loginDetails}
              handleLoginChange={handleLoginChange}
              onLoginSubmit={onLoginSubmit}
            />
          )} */}
      </div>

      <CDModal
        title={
          <CBFeedbackModalTitle
            className={"cb-likeModal-icon"}
            icon={<LikeOutlined style={{ fontSize: "25px", color: "white" }} />}
            title={"Provide additional feedback"}
          />
        }
        body={likedBotMsgBodyContent}
        open={isLikeBotMsg}
        onCancel={closeLikedBotMsgModal}
      />

      <CDModal
        title={
          <CBFeedbackModalTitle
            className={"cb-dislikeModal-icon"}
            icon={
              <DislikeOutlined style={{ fontSize: "25px", color: "white" }} />
            }
            title={"Provide additional feedback"}
          />
        }
        open={isDislikeBotMsg}
        onCancel={closeDislikedBotMsgModal}
        body={disLikedBotMsgBodyContent}
      />

      <CDModal
        title={
          <CBFeedbackModalTitle
            className={"cb-neutralModal-icon"}
            icon={<StarOutlined style={{ fontSize: "25px", color: "white" }} />}
            title={"Provide additional feedback"}
          />
        }
        open={isDisALikeEnhancement}
        onCancel={closeDislikeEnhancementModal}
        body={enhancementBotMsgBodyContent}
      />
    </>
  );
};

export default CBChatPopUp;
