import React, { useEffect, useState } from "react";
import "./AudioPlayer.css";

const AudioPlayer = ({ base64Audio, isNewBotMsg }) => {

  useEffect(() => {
    const audioElement = document.getElementById("audio");
    if (isNewBotMsg) {
      audioElement?.play();
    } else {
      audioElement?.pause();
    }
  }, [isNewBotMsg]);

  return (
    <div>
      <>
        <audio id="audio" controls>
          <source src={base64Audio} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      </>
    </div>
  );
};

export default AudioPlayer;
