import { notification } from "antd";

export const successNotification = (message, duration, placement, btn) => {
  notification.success({
    message: message,
    duration: duration ? duration : 3,
    placement: placement ? placement : "topRight",
    btn,
  });
};

export const errorNotification = (message, duration, placement, btn) => {
  notification.error({
    message: message,
    duration: duration ? duration : 3,
    placement: placement ? placement : "topRight",
    btn,
  });
};

export const infoNotification = (message, duration, placement, btn) => {
  notification.info({
    message: message,
    duration: duration ? duration : 3,
    placement: placement ? placement : "topRight",
    btn,
  });
};
