import { Modal } from "antd";
import React from "react";

const CBFullScreenModal = ({
  isOpenImage,
  fullScreenImageSrc,
  closeImageFullscreen,
}) => {
  return (
    <Modal
      centered
      open={isOpenImage}
      onCancel={() => closeImageFullscreen(false)}
      footer={null}
      width={1000}
    >
      <img
        className="ai-chatbot-full-screen-img"
        src={fullScreenImageSrc}
        alt=""
      />
    </Modal>
  );
};

export default CBFullScreenModal;
