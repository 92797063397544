import React, {useRef, useEffect} from "react";

export default function HtmlFragment({content}) {
  const contentRef = useRef();

  useEffect(() => {
    const fragment = document
      .createRange()
      .createContextualFragment(content);
    if (contentRef.current) {
      contentRef.current.appendChild(fragment);
    } else {
      return;
    }
  }, [content]);

  return <div ref={contentRef} />;
}
