import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { asyncLocalStorage } from "../../services/commonService";
import { useDispatch } from "react-redux";
import { login } from "../../store/feature/loginSlice";
import { setAuthHeader } from "../../AxiosAPI";

const CBRedirectPage = () => {
  //COMPONENT STATES
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const redirectionUrl = process.env.REACT_APP_URL_LOGIN
      ? process.env.REACT_APP_URL_LOGIN
      : "";

    const code = searchParams.get("code");
    const state = searchParams.get("state");

    if (
      code === undefined ||
      code === null ||
      state === undefined ||
      state === null
    ) {
      navigate("/");
    }

    const callBackData = {
      code: code,
      state: state,
    };

    const dexAuthorizeUrl = process.env.REACT_APP_LOGIN_AUTHORIZE_URL;

    axios
      .get(
        `${redirectionUrl}/code/${dexAuthorizeUrl}?code=${code}&state=${state}`
      )
      .then(function async(response) {
        const getToken = localStorage.getItem("_token");
        localStorage.removeItem("_token");

        if (getToken === "undefined" || getToken === "" || getToken === null) {
          if (response?.status === 200) {
            localStorage.setItem(
              "_token",
              response.headers["x-av-api-access-key"]
            );
            setAuthHeader(response.headers["x-av-api-access-key"]);
            dispatch(login(response.data));
            navigate("/");
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    setIsLoading(false);
  }, []);

  return <div>{isLoading ? "Loading..." : ""}</div>;
};

export default CBRedirectPage;
