import { Button, Col, Form, Input, Row } from "antd";
import FormItem from "antd/es/form/FormItem";
import TextArea from "antd/es/input/TextArea";
import axios from "axios";
import React, { useState } from "react";
import { postTheFeedback } from "../actions/chat/chat.action";
import {
  errorNotification,
  successNotification,
} from "../services/notification";

const useChatBotPopUp = () => {
  const [isLikeBotMsg, setLikeBotMsg] = useState(false);
  const [isDislikeBotMsg, setdislikeBotMsg] = useState(false);
  const [isDisALikeEnhancement, setDislikeEnhancement] = useState(false);
  const [activeMessageId, setActiveMessageId] = useState("");
  const [positiveFeedback, setPositiveFeedback] = useState({ response: "" });
  const [negativeFeedbackForm, setNegativeFeedbackForm] = useState({
    negativeFeedback: "",
    optionOne: "",
    optionTwo: "",
    optionThree: "",
    optionFour: "",
  });
  const [positiveFeedbackLoading, setPositiveFeedbackLoading] = useState(false);
  const [negativeFeedbackLoading, setNegativeFeedbackLoading] = useState(false);
  const [enchancementFeedbackForm, setEnhancementFeedbackForm] = useState({
    enhancementFeedback: "",
    optionOne: "",
    optionTwo: "",
    optionThree: "",
    optionFour: "",
  });

  const [positiveForm] = Form.useForm();
  const [negativeFeedback] = Form.useForm();
  const [enchancementFeedback] = Form.useForm();

  //On Like Bot Msg
  const openLikeBotMsgModal = (messageId) => {
    setActiveMessageId(messageId);
    // positiveForm.setFieldsValue({ response: "" });
    setLikeBotMsg(true);
  };

  const closeLikedBotMsgModal = (messageId) => {
    setLikeBotMsg(false);
    positiveForm.setFieldsValue({ response: "" });
    setPositiveFeedback({ response: "" });
  };

  const handleLikeModalSubmit = async () => {
    if (positiveFeedback.response) {
      setPositiveFeedbackLoading(true);

      const payload = {
        message_id: activeMessageId,
        like: 1,
        feedback: {
          text: positiveFeedback.response,
        },
      };

      try {
        const feedbackRes = await postTheFeedback(payload);

        if (feedbackRes?.status == 200) {
          closeLikedBotMsgModal();
          positiveForm.setFieldsValue({ response: "" });
          setPositiveFeedback({ response: "" });
          successNotification("Thank you for your feedback.");
        }
      } catch (error) {
        console.log(error);
        errorNotification("Something went wrong, please try again!!");
      }
      setPositiveFeedbackLoading(false);
    }
  };

  //On Dislike Bot Msg
  const openDislikeBotMsgModal = (messageId) => {
    setActiveMessageId(messageId);
    setdislikeBotMsg(true);
  };

  const closeDislikedBotMsgModal = () => {
    setdislikeBotMsg(false);
    resetNegativeFeedbackForm();
  };

  const handleDislikeModalSubmit = () => {
    closeDislikedBotMsgModal();
  };

  //Enhancement Modal
  const openDislikeEnhancementModal = () => {
    setDislikeEnhancement(true);
  };
  const closeDislikeEnhancementModal = () => {
    setDislikeEnhancement(false);
  };
  const handleDislikeEnhancementModalSubmit = () => {
    closeDislikeEnhancementModal();
    closeDislikedBotMsgModal();
  };

  const handlePositiveResponseChange = (e) => {
    const { value, name } = e.target;
    setPositiveFeedback({ response: value });
  };
  const handleChangeNegativeFeedback = (name, value) => {
    setNegativeFeedbackForm({ ...negativeFeedbackForm, [name]: value });
  };

  const resetNegativeFeedbackForm = () => {
    negativeFeedback.setFieldsValue({
      negativeFeedback: "",
    });
    negativeFeedback.setFieldsValue({
      optionOne: "",
    });
    negativeFeedback.setFieldsValue({
      optionTwo: "",
    });
    negativeFeedback.setFieldsValue({
      optionThree: "",
    });
    negativeFeedback.setFieldsValue({
      optionFour: "",
    });

    setNegativeFeedbackForm({
      negativeFeedback: "",
      optionOne: "",
      optionTwo: "",
      optionThree: "",
      optionFour: "",
    });
  };
  const handleSubmitNegativeFeedback = async () => {
    if (negativeFeedbackForm.negativeFeedback.trim()) {
      setNegativeFeedbackLoading(true);
      const options = [];
      const { optionOne, optionTwo, optionThree, optionFour } =
        negativeFeedbackForm;
      if (optionOne.trim()) options.push(optionOne);
      if (optionTwo.trim()) options.push(optionTwo);
      if (optionThree.trim()) options.push(optionThree);
      if (optionFour.trim()) options.push(optionFour);

      const payload = {
        message_id: activeMessageId,
        like: -1,
        feedback: {
          text: negativeFeedbackForm.negativeFeedback,
          options: options,
        },
      };

      try {
        const negativeFeedbackRes = await postTheFeedback(payload);

        if (negativeFeedbackRes?.status == 200) {
          handleDislikeModalSubmit();
          resetNegativeFeedbackForm();
          successNotification("Thank you for your feedback.");
        }
      } catch (error) {
        console.log(error);
        errorNotification("Something went wrong, please try again!!");
      }
      setNegativeFeedbackLoading(false);
    }
  };

  const handleChangeEnhancementFeedback = (name, value) => {
    setEnhancementFeedbackForm({ ...enchancementFeedbackForm, [name]: value });
  };

  const handleSubmitEnhancementFeedback = (e) => {
    if (enchancementFeedbackForm.enhancementFeedback.trim()) {
      enchancementFeedback.setFieldsValue({
        enhancementFeedback: "",
      });
      enchancementFeedback.setFieldsValue({
        optionOne: "",
      });
      enchancementFeedback.setFieldsValue({
        optionTwo: "",
      });
      enchancementFeedback.setFieldsValue({
        optionThree: "",
      });
      enchancementFeedback.setFieldsValue({
        optionFour: "",
      });

      setEnhancementFeedbackForm({
        enhancementFeedback: "",
        optionOne: "",
        optionTwo: "",
        optionThree: "",
        optionFour: "",
      });

      closeDislikeEnhancementModal();
      closeDislikedBotMsgModal();
    }
  };

  const likedBotMsgBodyContent = (
    <div className="mt-5">
      <Form form={positiveForm} onFinish={handleLikeModalSubmit}>
        <Form.Item
          name="response"
          label=""
          rules={[
            {
              required: true,
              message: "Feedback is required",
            },
          ]}
        >
          <TextArea
            name="response"
            className="cb-likeModal-input"
            value={positiveFeedback.response}
            onChange={(e) => handlePositiveResponseChange(e)}
            placeholder="What do you like about the response"
            autoSize={{ minRows: 4, maxRows: 6 }}
          />
        </Form.Item>
        <div className="mt-5 w-100 d-flex justify-end">
          <Button
            htmlType="submit"
            className="cb-common-button"
            disabled={
              !positiveFeedback.response || positiveFeedbackLoading
                ? true
                : false
            }
          >
            Submit Feedback
          </Button>
        </div>
      </Form>
    </div>
  );

  const disLikedBotMsgBodyContent = (
    <Form form={negativeFeedback} onFinish={handleSubmitNegativeFeedback}>
      <div className="mt-5">
        <Form.Item
          name="negativeFeedback"
          label=""
          rules={[
            {
              required: true,
              message: "Feedback is required",
            },
          ]}
        >
          <TextArea
            name="negativeFeedback"
            className="cb-likeModal-input"
            value={negativeFeedbackForm.negativeFeedback}
            onChange={(e) => {
              const { name, value } = e.target;
              handleChangeNegativeFeedback(name, value);
            }}
            placeholder="What was the issue with the response? How could it be improved"
            autoSize={{ minRows: 4, maxRows: 6 }}
          />
        </Form.Item>
        {/* <div>
          <h3 className="mt-4 f-20">
            Do you have recommendations for suggestive text? Add them below.
          </h3>
          <Row gutter={24}>
            <Col span={12} className="mt-4">
              <Form.Item name="optionOne" label="">
                <Input
                  type="text"
                  name="optionOne"
                  placeholder="Option 1"
                  className="normal-input big-input input-bg cb-common-input"
                  onChange={(e) => {
                    const { name, value } = e.target;
                    handleChangeNegativeFeedback(name, value);
                  }}
                  value={negativeFeedbackForm.optionOne}
                />
              </Form.Item>
            </Col>
            <Col span={12} className="mt-4">
              <Form.Item name="optionTwo" label="">
                <Input
                  type="text"
                  name="optionTwo"
                  placeholder="Option 2"
                  className="normal-input big-input input-bg cb-common-input"
                  onChange={(e) => {
                    const { name, value } = e.target;
                    handleChangeNegativeFeedback(name, value);
                  }}
                  value={negativeFeedbackForm.optionTwo}
                />
              </Form.Item>
            </Col>
            <Col span={12} className="mt-4">
              <Form.Item name="optionThree" label="">
                <Input
                  type="text"
                  name="optionThree"
                  placeholder="Option 3"
                  className="normal-input big-input input-bg cb-common-input"
                  onChange={(e) => {
                    const { name, value } = e.target;
                    handleChangeNegativeFeedback(name, value);
                  }}
                  value={negativeFeedbackForm.optionThree}
                />
              </Form.Item>
            </Col>
            <Col span={12} className="mt-4">
              <Form.Item name="optionFour" label="">
                <Input
                  type="text"
                  name="optionFour"
                  placeholder="Option 4"
                  className="normal-input big-input input-bg cb-common-input"
                  onChange={(e) => {
                    const { name, value } = e.target;
                    handleChangeNegativeFeedback(name, value);
                  }}
                  value={negativeFeedbackForm.optionFour}
                />
              </Form.Item>
            </Col>
          </Row>
        </div> */}

        <div className="mt-5 w-100 d-flex justify-end gap-10">
          {/* <Button
            onClick={() => {
              openDislikeEnhancementModal();
            }}
            className="cb-common-button"
            // disabled={isHandleSubmitTrade.isLoading ? true : false}
          >
            Submit Feedback with enhancement
          </Button> */}
          <Button
            onClick={() => {
              // handleDislikeModalSubmit();
            }}
            htmlType="submit"
            className="cb-common-button"
            disabled={
              !negativeFeedbackForm.negativeFeedback || negativeFeedbackLoading
                ? true
                : false
            }
          >
            Submit Feedback
          </Button>
        </div>
      </div>
    </Form>
  );

  const enhancementBotMsgBodyContent = (
    <Form
      form={enchancementFeedback}
      onFinish={handleSubmitEnhancementFeedback}
    >
      <div className="mt-5">
        <Form.Item
          name="enhancementFeedback"
          label=""
          rules={[
            {
              required: true,
              message: "Feedback is required",
            },
          ]}
        >
          <TextArea
            name="enhancementFeedback"
            className="cb-likeModal-input"
            value={enchancementFeedbackForm.enhancementFeedback}
            onChange={(e) => {
              const { name, value } = e.target;
              handleChangeEnhancementFeedback(name, value);
            }}
            placeholder="Please let us know how Dex can help you"
            autoSize={{ minRows: 4, maxRows: 6 }}
          />
        </Form.Item>
        <div>
          <h3 className="mt-4 f-20">
            Do you have recommendations for suggestive text? Add them below.
          </h3>
          <Row gutter={24}>
            <Col span={12} className="mt-4">
              <Form.Item name="optionOne" label="">
                <Input
                  type="text"
                  name="optionOne"
                  placeholder="Option 1"
                  className="normal-input big-input input-bg cb-common-input"
                  onChange={(e) => {
                    const { name, value } = e.target;
                    handleChangeEnhancementFeedback(name, value);
                  }}
                  value={enchancementFeedbackForm.optionOne}
                />
              </Form.Item>
            </Col>
            <Col span={12} className="mt-4">
              <Form.Item name="optionTwo" label="">
                <Input
                  type="text"
                  name="optionTwo"
                  placeholder="Option 2"
                  className="normal-input big-input input-bg cb-common-input"
                  onChange={(e) => {
                    const { name, value } = e.target;
                    handleChangeEnhancementFeedback(name, value);
                  }}
                  value={enchancementFeedbackForm.optionTwo}
                />
              </Form.Item>
            </Col>
            <Col span={12} className="mt-4">
              <Form.Item name="optionThree" label="">
                <Input
                  type="text"
                  name="optionThree"
                  placeholder="Option 3"
                  className="normal-input big-input input-bg cb-common-input"
                  onChange={(e) => {
                    const { name, value } = e.target;
                    handleChangeEnhancementFeedback(name, value);
                  }}
                  value={enchancementFeedbackForm.optionThree}
                />
              </Form.Item>
            </Col>
            <Col span={12} className="mt-4">
              <Form.Item name="optionFour" label="">
                <Input
                  type="text"
                  name="optionFour"
                  placeholder="Option 4"
                  className="normal-input big-input input-bg cb-common-input"
                  onChange={(e) => {
                    const { name, value } = e.target;
                    handleChangeEnhancementFeedback(name, value);
                  }}
                  value={enchancementFeedbackForm.optionFour}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>

        <div className="mt-5 w-100 d-flex justify-end gap-10">
          <Button
            htmlType="submit"
            className="cb-common-button"
            disabled={
              !enchancementFeedbackForm.enhancementFeedback ? true : false
            }
          >
            Submit
          </Button>
        </div>
      </div>
    </Form>
  );

  return {
    isLikeBotMsg,
    isDislikeBotMsg,
    isDisALikeEnhancement,
    likedBotMsgBodyContent,
    disLikedBotMsgBodyContent,
    enhancementBotMsgBodyContent,
    openLikeBotMsgModal,
    openDislikeBotMsgModal,
    closeDislikeEnhancementModal,
    closeDislikedBotMsgModal,
    closeLikedBotMsgModal,
  };
};

export default useChatBotPopUp;
